




import Discriminator from "../../types/Discriminator";
import Vue from "vue";
import VesselTypeOverview from '@/components/vessel/type/VesselTypeOverview.vue';

export default Vue.extend({
  components: {
    VesselTypeOverview
  },
  data() {
    return {
      discriminator: Discriminator.LAND_WORKERS
    }
  }
})
